.logo{
   height:60px;
   padding:7px;
}
.logo img {
    height:150%;
}
@media (max-width: 500px) {
    .logo img {
        height:120%;
    }
}
            
.Header {
    display: inline-flex;

}
.pata  {
    text-align: center;
    align-self: center;
    align-items: center;
    align-content: center;
    display: none;
    margin: 2%;
}
.pata a{
    text-align: center;
    display: block!important;
}