.footer{
    display:flex;
}
.footer a{
    font-size: 1rem;
    align-self:center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    display:flex;
    line-height: 1.43;
    margin:1.5%;
    color:rgba(0, 0, 0, 0.87);
}
.footer img {
    margin-left: 2%;
}