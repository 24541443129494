.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    display: inline-flex;
    visibility: hidden;
    color: black;
    text-align: center;
    border-radius: 6px;
    margin: 0 0 0 2px;
    right: 100%;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .day {
    padding: 1px;
    font-size: 0.875rem;
    display: inline-flex;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    line-height: 1.5rem;
  }