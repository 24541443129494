@media only screen and (min-width: 600px) {
    .swits {
      margin-right: 5%;
    }
  }
.swits{
    float: right;
}
.swits p{
    display: flex;
}
#space{
    margin: 4px;
}
#switchscase{
    margin-left: 10px;
    margin-right: 10px;
}
@media (max-width: 600px) {
    .swits {
        margin-left: 5px;        
    }

}